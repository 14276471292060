<template>
    <section class="content">
        <div class="container-fluid">
            <div class="card" v-if="isShipper">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="text-secondary">
                                <h2 class="float-left mr-2"><i class="fa fa-gift"></i> </h2>
                                <div class="mb-3">
                                    <h4 class="m-0"> Halo <strong>{{username}} !</strong></h4>
                                    <div>Ayo kirimkan paketmu sekarang</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="btn-group float-right">
                              <button type="button" class="btn btn-primary btn-lg dropdown-toggle dropdown-icon" data-toggle="dropdown"><i class="fas fa-plus"></i> Buat Pesanan
                              </button>
                              <div class="dropdown-menu dropdown-menu-right">
                                <router-link to="/order/create" class="dropdown-item">
                                <i class="fas fa-edit"></i> Buat Manual
                            </router-link>
                                <router-link to="/order/import" class="dropdown-item">
                                <i class="fas  fa-upload"></i> Import
                            </router-link>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-3 col-md-3 col-sm-5 col-xs-12">
                    <div class="input-group mb-3">
                        <input type="text" ref="daterange" class="form-control" style="border-right: 0" />
                        <div class="input-group-append">
                            <div class="input-group-text" style="background-color: #fff">
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-if="isReady"><span class="fa fa-calendar"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"><i class="hxf hxf-draft"></i></span>
                        <div class="info-box-content">
                            <h5 class="info-box-text">Draft</h5>
                            <h5 class="info-box-number">{{ dataStatus.draft }}</h5>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-danger elevation-1"><i class="hxf hxf-request-pickup"></i></span>
                        <div class="info-box-content">
                            <h5 class="info-box-text">Request Pickup</h5>
                            <h5 class="info-box-number">{{ dataStatus.reqPickup }}</h5>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-success elevation-1"><i class="hxf hxf-delivery"></i></span>
                        <div class="info-box-content">
                            <h5 class="info-box-text">Pengiriman</h5>
                            <h5 class="info-box-number">{{ dataStatus.delivery }}</h5>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-warning elevation-1"><i class="hxf hxf-delivered"></i></span>
                        <div class="info-box-content">
                            <h5 class="info-box-text">Telah diterima</h5>
                            <h5 class="info-box-number">{{ dataStatus.delivered }}</h5>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon bg-warning elevation-1"><i class="hxf hxf-rdk"></i></span>
                        <div class="info-box-content">
                            <h5 class="info-box-text">RDK</h5>
                            <h5 class="info-box-number">
                                {{ dataStatus.rdk }}
                            </h5>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-info elevation-1"><i class="hxf hxf-rtk"></i></span>
                        <div class="info-box-content">
                            <h5 class="info-box-text">RTK</h5>
                            <h5 class="info-box-number">{{ dataStatus.rtk }}</h5>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-success elevation-1"><i class="hxf hxf-returned"></i></span>
                        <div class="info-box-content">
                            <h5 class="info-box-text">Selesai Retur</h5>
                            <h5 class="info-box-number">{{ dataStatus.returned }}</h5>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-danger elevation-1"><i class="hxf hxf-nochange"></i></span>
                        <div class="info-box-content">
                            <h5 class="info-box-text">Tidak bergerak</h5>
                            <h5 class="info-box-number">{{ dataStatus.notMove }}</h5>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="info-box mb-3">
                        <span class="info-box-icon text-success text-center-v"><i class="fas fa-hand-holding-usd"></i></span>
                        <div class="info-box-content">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-center-v">
                                    <h4 class="text-padding-12">Paid</h4>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 text-center-v">
                                    <h4 class="info-box-number text-right text-padding-12">
                                        Rp. -
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="info-box">
                        <span class="info-box-icon text-danger text-center-v"><i class="fas fa-bell"></i></span>
                        <div class="info-box-content">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-center-v">
                                    <h4 class="text-padding-12">Unpaid</h4>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 text-center-v">
                                    <h4 class="info-box-number text-right text-padding-12">
                                        Rp. -
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <div class="info-box bg-primary mb-3">
                        <span class="info-box-icon text-center-v icon-opacity"><i class="fas fa-clock"></i></span>
                        <div class="info-box-content box-custom">
                            <h6 class="vertical-center">History Payment</h6>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <div class="info-box bg-primary mb-3">
                        <span class="info-box-icon text-center-v icon-opacity"><i class="fas fa-clock"></i></span>
                        <div class="info-box-content box-custom">
                            <h6 class="vertical-center">Outstanding Transaction</h6>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="info-box">
                        <span class="info-box-icon text-info text-center-v"><i class="fas fa-gift"></i></span>
                        <div class="info-box-content">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 text-center-v">
                                    <h4 class="text-padding-12">Cashback</h4>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 text-center-v">
                                    <h4 class="info-box-number text-right text-padding-12">
                                        Rp. -
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="info-box mb-3">
                        <canvas ref="myChart"></canvas>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="info-box">
                        <canvas ref="myChart2"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Daterangepicker from "daterangepicker";
import Chart from "chart.js";
import { authFetch, auth } from "@/libs/hxcore";
import moment from "moment";
import "daterangepicker/daterangepicker.css";
var myChart = null;
export default {
    name: "Home",
    data: function() {
        return {
            isShipper: false,
            dateStart: moment().startOf('month').format("YYYY-MM-DD"),
            dateEnd: moment().format("YYYY-MM-DD"),
            dataStatus: {},
            dataCourierPie: {
                type: "pie",
                data: [],
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: "top",
                        },
                        title: {
                            display: true,
                            text: "Data Courier",
                        },
                    },
                },
            },
            loading: false,
        };
    },
    created: function() {

        var info = auth.user();
        if (info) {
            this.username = info.display_name;
            this.isShipper = info.role_id > 10;
        }
    },
    computed: {
        isReady: function() {
            return !this.loading;
        },
    },
    methods: {
        onReload() {
            var params = { dt1: this.dateStart, dt2: this.dateEnd };
            this.loading = true;
            var search = new URLSearchParams(params);
            authFetch(`/dashboard/statistic?${search}`).then((res) => {
                res.json().then((json) => {
                    this.loading = false;
                    //   console.log(json.pieChart);
                    if (json.success) {
                        this.dataCourierPie.data = json.pieChart;
                        myChart.update();
                        this.dataStatus = json.status;
                    }
                });
                //loading(false);
            });
        },
    },
    mounted() {
        var self = this;
        new Daterangepicker(this.$refs.daterange, {
            startDate: moment().startOf('month'),
            endDate: moment(),
        }, function(d1, d2) {
            self.dateStart = d1.format("YYYY-MM-DD");
            self.dateEnd = d2.format("YYYY-MM-DD");
            self.onReload();
            //alert(self.dateStart+'...'+self.dateEnd)
        });
        this.onReload();
        var ctx = this.$refs.myChart.getContext("2d");

        const DATA_COUNT = 5;
        const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };

        // const data = {
        //   labels: ["IDExp", "JX", "JNE"],
        //   datasets: [
        //     {
        //       label: "Dataset 1",
        //       data: [200, 300, 400],
        //       backgroundColor: ["#ff0", "#ff6384", "#17a2b8"],
        //     },
        //   ],
        // };

        myChart = new Chart(ctx, this.dataCourierPie);

        /*var ctx2 = this.$refs.myChart2.getContext("2d");
        var myChart2 = new Chart(ctx2, {
            type: "bar",
            data: {
                labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
                datasets: [{
                    label: "# of Votes",
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 1,
                }, ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });*/
    },
};
</script>
<style>
.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    /*text-align: center;*/
}

.text-center-v {
    align-self: center;
}

.text-padding-12 {
    padding-top: 14px;
    overflow-wrap: anywhere;
}

@media (min-width: 300px) and (max-width: 1023px) {
    .text-right {
        text-align: left !important;
    }

    .text-padding-12 {
        padding-top: 0px;
        overflow-wrap: anywhere;
    }
}
</style>